import React, { useEffect } from "react";
import PaymentView from "./PaymentView";
import { ElementsConsumer } from "@stripe/react-stripe-js";
import { PAYMENT_ID } from "../../api-services/constants";
import StripeProvider from "../StripeProvider";
import withCaptcha from "../../hooks/withCaptcha";

const PaymentViewWrapper = (props) => {
  const {
    paymentTypeDetails,
    paymentTypeDetailsForWallets,
    order,
    checkoutPriceDetails,
    pageContext,
  } = props;

  if (
    (paymentTypeDetailsForWallets ||
      (paymentTypeDetails && paymentTypeDetails?.paymentType === PAYMENT_ID.STRIPE)) &&
    !order.orderPlaced &&
    checkoutPriceDetails.total
  ) {
    const options = {
      mode: "payment",
      amount: Math.floor(checkoutPriceDetails.total * 100),
      currency: pageContext.business.currency.toLowerCase(),
      paymentMethodCreation: "manual",
    };

    return (
      <StripeProvider
        options={options}
        paymentTypeDetails={paymentTypeDetailsForWallets || paymentTypeDetails}
      >
        <ElementsConsumer>
          {({ stripe, elements }) => (
            <PaymentView {...{ stripe, elements, ...props }} />
          )}
        </ElementsConsumer>
      </StripeProvider>
    );
  }

  return <PaymentView {...props} />;
};

export default PaymentViewWrapper;

export const PaymentViewWithRecaptcha = withCaptcha(PaymentViewWrapper);
